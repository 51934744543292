.tiny-letter-sign-up {
    position: fixed;
    align-self: center;
    text-align: center;
    top: 20%;
    background: #fffcf8;
    border-radius: min(40px, 4vw);
    font-family: 'averia-bold', Futura, sans-serif;
    font-size: min(3.4vw, 22px);
    color: #534741;
    padding-bottom: min(8%, 40px);
    margin: 0 auto;
    left: 0;
    right: 0;
    cursor: url("../../assets/cursors/cursor_fish_auto.png") 10 10, auto;
}

.tiny-letter-sign-up:focus {
    outline: none;
    outline-width: 0;
}

.tiny-letter-sign-up img[alt="background"] {
    margin-top: 3vw;
    width: 30%;
}

.tiny-letter-sign-up img[alt="heart"] {
    /* margin-top: 3vw; */
    width: 5%;
}

@media screen and (max-width: 1024px) {
    .tiny-letter-sign-up {
        width: 95%;
    }

    .email-input {
        width: 90%;
    }

    .submit-button {
        width: 94%;
    }
}

@media screen and (min-width: 1024px) {
    .tiny-letter-sign-up {
        width: min(50%, 650px);
    }

    .email-input {
        width: 70%;
    }

    .submit-button {
        width: 74%;
    }
}

.email-input {
    font-family: 'averia-bold', Futura, sans-serif;
    background: #efeae5;
    color: #534741;
    border: none;
    font-size: min(4vw, 22px);
    padding: 2%;
    margin: 2%;
    border-radius: min(10px, 1.5vw);
    user-select: text;
    -webkit-user-select: text !important;
    cursor: url("../../assets/cursors/cursor_input.png") 10 10, text;
}

.email-input:focus {
    outline: 0 !important;
    background: #53474122;
}

.submit-button {
    font-family: 'averia-bold', Futura, sans-serif;
    background: #534741;
    color: white;
    border-radius: min(10px, 1.5vw);
    border: none;
    line-height: min(10vh,60px);
    font-size: min(2vw,24px);
    cursor: url("../../assets/cursors/sandy_yes.png") 30 30, pointer;
}

.submit-button:hover {
    background: #423934;
}