.intro {
    padding-top: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.intro_row {
    display: flex;
    align-items: center;
    width: auto;
    max-width: 90%;
}

.feature {
    display: flex;
    flex-direction: column;
    background: white;
    width: 540px;
    border-radius: min(60px, 8vw);

    margin-bottom: min(30px, 4vh);
    margin-top: min(30px, 4vh);

    margin-right: min(60px, 6vw);
    margin-left: min(60px, 6vw);
}

.feature_header {
    padding: min(35px, 6vh);
}

.feature_content {
    position: relative;
    width: 100%;
    flex: 1;
    min-height: 40%;
    border-radius: min(60px, 8vw);
}

.feature_title {
    letter-spacing: 1px;
    color: #333333;
    margin-bottom: min(8px,3vh);
}

.feature_notion {
    width: 100%;
    letter-spacing: 0.3px;
    color: #83b586;
}

.intro_row .card {
    width: 200px;
    margin-left: 3vw;
    margin-right: 3vw;
    filter: drop-shadow(0px 0px 5px #33333333);
    cursor: url("../../assets/cursors/cursor_fish_active.png") 10 10, auto;
}

@media screen and (max-width:768px) {
    .feature_image {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .feature_title {
        font-size: 3.5vw;
    }

    .feature_notion {
        font-size: 2.6vw;
    }
}

@media screen and (min-width: 1024px) {
    .feature_title {
        font-size: 30px;
    }

    .feature_notion {
        font-size: 22px;
    }
}

.content1 {
    border-radius: min(60px, 8vw);
    position: absolute;
    bottom: 0;
    width: 120%;
    overflow: hidden;
}

.content1 img {
    width: 100%;
    margin-left: -10%;
    margin-bottom: -20%;
}

.content3 {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.content3 canvas {
    width: 80%;
}

.content3 button {
    background: none;
    position: absolute;
    height: 50%;
    align-self: center;
    text-align: center;
    transition: .1s;
    cursor: none;
}

@media(hover: hover) and (pointer: fine) {
    .content3 button:hover {
        transform: scale(1.4);
    }
}

.content3 button:nth-of-type(1) {
    left: -10%;
}

.content3 button:nth-of-type(2) {
    right: -10%;
}

.content3 button:nth-of-type(3) {
    height: 20%;
    top: 60%;
    display: none;
}

.content3 button:nth-of-type(3).mobile {
    height: 20%;
    top: 60%;
    display: block
}

.content3 button img {
    width: 100%;
    height: 100%;
}

.content2 {
    position: absolute;
    width: 100%;
    height: 150%;
    bottom: 0;
    overflow: hidden;
    border-radius: 0 0 min(60px, 8vw) min(60px, 8vw);
    cursor: url("../../assets/cursors/cursor_fish_active.png") 10 10, auto;
}

#tip_rhythm {
    width: 50%;
    margin-left: 45%;
    margin-top: 20%;
}

#tip_melody {
    width: 55%;
    margin-left: 5%;
    margin-top: -55%;
}

#tip_chords {
    width: 60%;
    margin-left: 25%;
    margin-top: -60%;
}

.content4 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 0 0 min(60px, 8vw) min(60px, 8vw);
    overflow: hidden;
}

.content4>img:nth-of-type(1) {
    object-fit: contain;
    position: relative;
    width: 50%;
    margin-left: 10%;
    top: -5%;
}


.content4>img:nth-of-type(2) {
    position: relative;
    object-fit: contain;
    width: 50%;
    top: 15%;
}