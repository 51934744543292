
  .sounds-container {
    width: 600px;
    background: #fbb03b;
    border-radius: min(60px, 8vw);
    padding: 10px;
    margin: 30px;
  }

  .sounds-container img{
    width: 100px;
    margin: 10px;
    padding: 10px;
    border-radius: 30px;
    background-color: #f7931e;
  }
