.character-container {
    width: 600px;
    background: #fbb03b;
    border-radius: min(60px, 8vw);
    padding: 10px;
    margin: 30px;
  }

  .character-container img{
    width: min(150px,40%);
  }

  .items-title {
    font-size: min(26px,3vw);
    letter-spacing: 0.4px;
    color: #ffffff;
    padding: min(30px,6%);
}