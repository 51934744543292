.trailer-container {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
}

.trailer-container img{
    height: 432px;
    margin-right: -10px;
    cursor: url("../../assets/cursors/echo_play_dumb.png") 30 30, pointer;
}

.trailer-video {
    background-color: white;
    width: 768px;
    max-width: 80%;
    border-radius: 2vw;
    padding: 6px;
}

@media screen and (max-width:1024px) {
    .trailer-container img {
        display: none;
    }
}
