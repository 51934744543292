
.banner {
    width: 100vw;
    height: 16vh;
    display: flex;
    justify-content: center;
    padding-top: 3vh;
}

.banner img {
    height: 100%;
    max-width: 80%;
    object-fit: contain;
}