.terms-container {
    background-color: #fffcf8;
}

.terms {
    font-family: 'averia-bold', Futura,Helvetica Neue,Arial,Segoe UI,PingFang SC,Microsoft Yahei,Hiragino Sans GB,sans-serif;
    background-color: #fffcf8;
    color: #534741;
    padding: 50px;
    max-width: 800px;
    margin: auto;
}

.terms h1 {
    font-weight: 800;
    font-size: 28px;
    margin: 0 0 30px;
}

.terms h2 {
    font-weight: 600;
    font-size: 24px;
    margin: 24px 0 24px;
    color: #534741;
}

.terms h3 {
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 18px;
}

.terms p {
    font-size: 16px;
    margin: 0 0 16px;
    color: #867a72;
    letter-spacing: 0.1px;
}

.terms li {
    font-size: 16px;
    margin: 0 0 16px;
    color: #867a72;
    letter-spacing: 0.1px;
}